/* src/pages/PartnerShips/PartnerShips.scss */
@import '../../styles/variables';
.contact-page {
  padding: 4rem 0;
  text-align: center;

  .contact-form {
    max-width: 600px;
    margin: 0 auto;
    text-align: left;

    .MuiTextField-root {
      margin-bottom: 1rem;
    }

    .MuiButton-root {
      display: block;
      margin: 1.5rem auto 0;
      background-color: $primary-color;
      color: $secondary-color;

      &:hover {
        background-color: darken($primary-color, 10%);
      }
    }
  }
}
.page-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.content-wrap {
  flex: 1; /* Makes the content area take up the available space */
}

.fixed-footer {
  background-color: $secondary-color;
  padding: 1rem;
  text-align: center;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
}