/* src/components/PropertyDetails/PropertyDetails.scss */
@import '../../styles/variables';
.property-details {
  padding: 2rem 0; /* Add some padding to the section */

  .property-description,
  .amenities-text {

    padding: 1rem 2rem; /* Add some inner spacing to each section */
  }

  .amenities-title {
    font-weight: 200;
    margin-bottom: 0.5rem;
    color: $black;
    text-transform: uppercase;
    letter-spacing: 3px;
  }
  .property-title {
    font-weight: 200;
    margin-bottom: 0.5rem;
    position: relative;
    text-transform: uppercase;
    letter-spacing: 3px;
  }
}
