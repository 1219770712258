/* src/styles/globals.scss */
@import 'variables';
@import 'mixins';
@import url('https://fonts.googleapis.com/css2?family=Lora:wght@200&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Muli:wght@400;700&display=swap');
body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  background-color: $white;
  color: $black;
}

a {
  text-decoration: none;
  color: inherit;
}
h1, h2, h3, h4, h5, h6 {
  font-family: "Lora", serif !important;
  font-optical-sizing: auto;
  font-weight: 200;
  font-style: normal;
  text-transform: capitalize !important;
}