/* src/components/GalleryInt/GalleryInt.scss */
@import '../../styles/variables';
.gallery-section {
  padding: 2rem 0;

  margin-top: 50px;
  margin-bottom: 50px;
}

.gallery-image {
  width: 100%;               /* Make the image fill the grid cell */
  height: 300px;             /* Set a fixed height to make images consistent */
  object-fit: cover;         /* Maintain the aspect ratio and cover the area */
  cursor: pointer;           /* Indicate that the image is clickable */
  transition: transform 0.3s ease-in-out;

  &:hover {
    transform: scale(1.05);  /* Slight zoom effect on hover */
  }
}
.gallery-int-contain {
  background: #FFF !important;
  padding: 3rem 1px;
}

.selected-image {
  width: 100%;
  height: auto;
  border-radius: 8px;
}
/* src/pages/GalleryInt/GalleryInt.scss or src/pages/PartnerShips/PartnerShips.scss */

.page-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.content-wrap {
  flex: 1; /* Makes the content area take up the available space */
}

.fixed-footer {
  background-color: $secondary-color;
  padding: 1rem;
  text-align: center;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
}
