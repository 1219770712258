/* src/components/MapSection/MapSection.scss */
@import '../../styles/variables';
.map-section {
  padding: 2rem 0;
  text-align: center;

  h5 {
    margin-bottom: 1.5rem;
    color: $black;
  }

  .map-image {
    width: 100%;               /* Full width */
    height: 700px;             /* Adjust the height to reduce its size */
    filter: grayscale(100%);   /* Apply black and white filter */
    object-fit: cover;         /* Maintain aspect ratio while covering the container */
    border: none;              /* Remove any border around the image */
    border-radius: 0;          /* Ensure no rounded corners */
  }
}