/* src/components/GalleryInt/GalleryInt.scss */
@import '../../styles/variables';
.gallery-section {
  padding: 2rem;

  .room-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    cursor: pointer;
  }

  .square-box {
    width: 100%;
    padding-top: 100%; /* This makes it a perfect square */
    position: relative;
    overflow: hidden;
  }

  .square-box img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
    li {
      margin-bottom: 0.5rem;
    }
  }

  .full-size-image {
    width: 100%;
    height: auto;
    object-fit: contain;
  }

  button {
    margin-top: 1rem;
  }
}


.gallery-image {
  width: 100%;               /* Make the image fill the grid cell */
  height: 300px;             /* Set a fixed height to make images consistent */
  object-fit: cover;         /* Maintain the aspect ratio and cover the area */
  cursor: pointer;           /* Indicate that the image is clickable */
  transition: transform 0.3s ease-in-out;

  &:hover {
    transform: scale(1.05);  /* Slight zoom effect on hover */
  }
}

.selected-image {
  width: 100%;
  height: auto;
  border-radius: 8px;
}
/* src/pages/GalleryInt/GalleryInt.scss or src/pages/PartnerShips/PartnerShips.scss */

.page-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.content-wrap {
  flex: 1; /* Makes the content area take up the available space */
}

.fixed-footer {
  background-color: $secondary-color;
  padding: 1rem;
  text-align: center;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
}
