/* src/components/Navbar/Navbar.scss */
@import '../../styles/variables';
.navbar {
  background-color: #121212;
}

.toolbar {
  display: flex;
  justify-content: space-between;
}

.nav-container {
  display: flex;
  align-items: center;
}

.nav-link {
  margin: 0 16px;
  font-size: 16px;
  text-transform: none;

  &:hover {
    background-color: transparent;
    color: #7b7b7b;
  }
}

img {
  height: 100px;
  width: auto;
}

@media (max-width: 768px) {
  .nav-link {
    margin: 0; /* Adjust margin for mobile */
  }
}
