/* src/components/AmenitySection/AmenitySection.scss */
@import '../../styles/variables';
.amenity-section {
  border: 1px solid #ccc;
  padding: 16px;
  border-radius: 4px;
  margin-bottom: 16px;
  min-height: 300px; /* Ensures all boxes are the same height */
  display: flex;
  flex-direction: column;
  justify-content: flex-start; /* Makes sure the content starts at the top */
  background: #ffffff;
}

.amenity-title {
  margin: 0 0 16px 0; /* Ensures consistent space below the title */
  text-align: left;
  border-bottom: 2px solid #d5d5d5; /* Adds the bottom border under the title */
  padding-bottom: 8px; /* Adds padding between title text and border */
}

.amenity-list-item {
  padding-left: 0; /* Removes default padding */
}
