/* src/components/ContactSection/ContactSection.scss */
@import '../../styles/variables';
/* src/components/ContactSection/ContactSection.scss */
.contact-section {
  background: #F5F5F5;
  padding: 2rem 1rem;
  text-align: center;

  .contact-title {
    margin-bottom: 1.5rem;
    color: $black;
  }

  .agent-card-container {
    display: flex;
    justify-content: center; /* Centers the card horizontally */
  }

  /* src/components/ContactSection/ContactSection.scss */
  .agent-card {
    display: flex;
    align-items: flex-start; /* Align the content to the top */
    width: 80%;
    max-width: 1200px;
    padding: 1.5rem;
    background-color: $white;
    border-radius: 0px;
    //box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    margin-bottom: 0.5rem;
  }

  .agent-photo {
    width: 150px; /* Set a fixed width for the photo */
    height: 150px; /* Set a fixed height for the photo */
    flex-shrink: 0; /* Prevent the image from shrinking */
    border-radius: 50%;
    object-fit: cover; /* Ensure the image maintains its aspect ratio */
    margin-right: 1.5rem; /* Add space between the photo and the text */
  }

  .agent-info {
    flex: 1; /* Allow the text to take up the remaining space */
    text-align: left;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 1rem;
    h6 {
      margin-bottom: 0.5rem;
      color: $black;
    }

    p {
      color: $black;
      margin-bottom: 1rem;
      line-height: 1.4; /* Adjust line height for better readability */
    }

    .MuiButton-root {
      background-color: $primary-color;
      color: $secondary-color;
      padding: 0.75rem 2rem;
      font-size: 1rem;
      border-radius: 2px;

      &:hover {
        background-color: darken($primary-color, 10%);
      }
    }
  }
  .agent-actions {
    display: flex;
    gap: 10px; /* Adds space between the icons */
    align-items: center;
  }

  .agent-social {
    display: flex;
    gap: 0.5rem; /* Space between the social buttons */
  }
}
