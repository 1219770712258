/* src/components/Hero/Hero.scss */
@import '../../styles/variables';
/* src/components/Hero/Hero.scss */
.hero-carousel {
  position: relative;
  width: 100%;
  height: 80vh; /* Adjust the height of the carousel */
  overflow: hidden;

  .slick-prev,
  .slick-next {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    z-index: 1;

  }

  .slick-prev {
    left: 10px;
  }

  .slick-next {
    right: 10px;
  }

  .slick-dots {
    bottom: 10px;
  }

  .slick-dots li button:before {
    font-size: 10px;
    color: white;
  }

  .hero-slide {
    width: 100%;
    height: 80vh; /* Make the image height consistent with the carousel */
  }

  .hero-image {
    width: 100%;
    height: 100%;
    object-fit: cover; /* Ensure the image covers the entire container */
  }
}
