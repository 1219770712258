/* src/components/PropertyDescription/PropertyDescription.scss */
@import '../../styles/variables';
.property-description {
  text-align: left; /* Align all text to the left */
  padding: 1rem 2rem;
  .square-box {
    width: 100%;
    margin-right: 50px;

  }
  .room-image {
    width: 100%;
    height: 600px;
    object-fit: cover;
    cursor: pointer;
  }
  .property-title {
    font-weight: 200;
    margin-bottom: 1rem;
    position: relative;
    text-transform: uppercase;
    letter-spacing: 3px;
    text-align: left;
  }

  .property-title::after {
    content: '';
    display: block;
    width: 100%; /* Full width of the container */
    height: 1px; /* Thickness of the border */
    background-color: $black; /* Color of the border */
    margin-top: 0.5rem; /* Space between the title and the border */
  }

  .property-description-text {
    margin-top: 1rem;
    color: $black;
    letter-spacing: 3px;
    font-weight: 200;
  }
}