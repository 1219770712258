@import '../../styles/variables';
/* src/components/AmenityHighlight/AmenityHighlight.scss */
.amenity-highlight {
  display: flex;
  align-items: center;
  padding: 0.5rem 0;
  background-color: $secondary-color;
  border-radius: 8px;
  width: 100%;

  svg {
    font-size: 2rem;
    color: $primary-color;
    margin-right: 0.5rem; /* Space between the icon and the label */
  }

  .MuiTypography-subtitle1 {
    color: $black;
    font-weight: 500;
  }
}
