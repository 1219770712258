@import '../../styles/variables';

.property-title {

  margin-bottom: 3.5rem;
  position: relative;
  font-weight: 200;
  text-transform: uppercase;
  letter-spacing: 3px;
  text-align: center;

}
.contain-bg {
background: #F5F5F5;
  padding: 25px;
}