.contact-form {
  background-color: #F9F9F9;
  padding: 30px;
  border-radius: 0px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);

  .form-title {
    color: #333;
    font-size: 1rem;
    font-weight: bold;
    margin-bottom: 20px;
  }

  .form-input {
    margin-bottom: 20px;

    .MuiOutlinedInput-root {
      background-color: #fff; /* Set the input background to white */

      /* Remove the border */
      fieldset {
        border-color: transparent;
      }

      &:hover fieldset {
        border-color: transparent; /* Prevent hover border */
      }

      &.Mui-focused fieldset {
        border-color: transparent; /* Prevent focused border */
      }

      /* Add padding inside the input fields */
      input {
        padding: 20px;
      }
    }

    .MuiInputLabel-outlined {
      color: #666; /* Optional: Label text color */
    }
  }

  .submit-button {
    background-color: #000;
    color: #fff;
    padding: 10px 20px;
    width: 200px;
    height: 65px;

    &:hover {
      background-color: #4a4a4a;
    }
  }
}
