/* src/pages/GalleryPage/_GalleryPage.scss */
.gallery-page-container {
  padding: 1rem;
}

.gallery-image {
  width: 100%;
  height: auto;
  cursor: pointer;
  transition: transform 0.2s ease-in-out;

  &:hover {
    transform: scale(1.05);
  }
}

/* Modal styles with bigger size */
.gallery-modal .modal-content {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  height: auto;
  max-width: 100%; /* Make modal wider */
  margin: 0 auto;
}

.gallery-modal .selected-image {
  max-width: 90%; /* Make image bigger */
  max-height: 90%;
  position: relative;
}

/* Updated arrow styles: black background, white icon, and 50% smaller */
.gallery-modal .modal-nav {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  color: white;
  background-color: black;
  z-index: 1;
  height: 30px; /* 50% smaller */
  width: 30px;  /* 50% smaller */
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

.gallery-modal .left-arrow {
  left: -15px; /* Closer to the image */
}

.gallery-modal .right-arrow {
  right: -15px; /* Closer to the image */
}

.gallery-modal .modal-nav:hover {
  background-color: black; /* Keep the black background on hover */
}
