.footer {
  background-color: #1a1b1d;
  color: white;
  padding-top: 40px;
  text-align: center;

  .footer-cta {
    color: #fff;
    border-bottom: 1px solid #999;

    .footer-cta-container {
      display: flex;
      justify-content: space-between; /* Aligns text left and button right */
      align-items: center;
      padding: 80px 0; /* Increased padding for more space at the top and bottom */

      @media (max-width: 768px) {
        flex-direction: column;
        text-align: center;
      }
    }

    .footer-cta-title {
      font-size: 2.5rem;
      font-weight: 400;
      text-align: left;

      @media (max-width: 768px) {
        font-size: 2rem;
        text-align: center;
        margin-bottom: 20px;
      }
    }

    .footer-cta-button {
      background-color: #fff;
      color: #000;
      padding: 15px 30px; /* Increased padding for button */
      border-radius: 0;
      font-size: 16px;

      @media (max-width: 768px) {
        padding: 12px 24px;
      }
    }
  }


  .footer-content {
    padding: 40px 0;

    .footer-grid {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      @media (max-width: 768px) {
        justify-content: center;
      }
    }

    .footer-column {
      text-align: left;
      padding: 16px;
      .footer-logo {
        max-width: 150px;
        margin-bottom: 16px;
      }
      .footer-title {
        font-size: 1.5rem;
        margin-bottom: 16px;
      }

      .footer-links {
        list-style: none;
        padding: 0;
        li {
          margin-bottom: 8px;
          a {
            color: white;
            text-decoration: none;
            &:hover {
              text-decoration: underline;
            }
          }
        }
      }

      .footer-contact-info {
        list-style: none;
        padding: 0;
        li {
          margin-bottom: 8px;
        }
      }
    }
  }

  .footer-bottom {
    background-color: #111;
    padding: 20px 0;
    .footer-bottom-text {
      font-size: 0.875rem;
    }
  }
}
