.contact-page {

  padding: 40px 0;

  .contact-heading {
    color: #333;
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 10px;
  }

  .contact-title {
    color: #000;
    font-size: 2.5rem;
    font-weight: bold;

    .highlight {
      color: #616161; /* Highlight color */
    }
  }

  .contact-description {
    margin-top: 20px;
    font-size: 1rem;
    color: #666;
  }

  .contact-form {
    background-color: #F9F9F9;
    padding: 30px;
    border-radius: 0px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);

    .form-title {
      color: #333;
      font-size: 1.5rem;
      font-weight: bold;
      margin-bottom: 20px;
    }

    .form-input {
      margin-bottom: 20px;
      .MuiOutlinedInput-root {
        background-color: #fff; /* Set the input background to white */

        /* Remove the border */
        fieldset {
          border-color: transparent;
        }

        &:hover fieldset {
          border-color: transparent; /* Prevent hover border */
        }

        &.Mui-focused fieldset {
          border-color: transparent; /* Prevent focused border */
        }

        /* Optional: Add padding inside the input fields */
        input {
          padding: 20px;
        }
      }

    }

    .submit-button {
      background-color: #1e88e5;
      color: #fff;
      padding: 10px 20px;
      width: 200px;
      height:65px;
      &:hover {
        background-color: #166bb5;
      }
    }
  }
}
